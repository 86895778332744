import { ButtonLink, Button } from '@sh24/ui-components'
import Link from 'next/link'
import { useRichTextDocumentContext } from '../../contexts/rich-text-document-context'

interface ResultsButtonInterface {
  action: string;
  displayText: string;
  variation: string;
}

const ResultsButton = ({ action, displayText, variation }: ResultsButtonInterface) => {
  const { sh24Uid, ctOfferToken } = useRichTextDocumentContext()

  switch (action) {
    case 'findClinic':
      return (
        <ButtonLink fullWidthOnMobile variation={variation.toLowerCase()}>
          <Link href={`/results/${sh24Uid}/find-your-local-clinic`} prefetch={false}>
            {displayText}
          </Link>
        </ButtonLink>
      )
    case 'orderFreeTreatment':
      return (
        <ButtonLink fullWidthOnMobile variation={variation.toLowerCase()}>
          <Link href={`/orders/chlamydia-treatment/new?token=${ctOfferToken}`} prefetch={false}>
            {displayText}
          </Link>
        </ButtonLink>
      )
    default:
      return (
        <Button
          onClick={() => {
            // eslint-disable-next-line no-alert
            alert(`${action}`)
          }}
          variation={variation.toLowerCase()}
          text={displayText}
          className="mb-sm"
        />
      )
  }
}

export default ResultsButton
